
<template>
  <div class="col-md-7 col-sm-7 mt-10 boxmain" v-if="is_loading">
    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                    {{$t('business.title')}}
                </h2>

              </div>
            </div>

          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12col-md-12 block-1">
                <article class="art_text table-responsive-test">
                  <div class="table-responsive">
                    <table class="table table-bordered" id="tableInvoice">
                      <thead>
                        <tr>
                          <th><Skeleton/></th>
                          <th><Skeleton/></th>
                          <th><Skeleton/></th>
                          <th><Skeleton/></th>
                          <th><Skeleton/></th>
                        <th><Skeleton/></th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v->
                          <td><strong>
                             <Skeleton/>
                          </strong></td>
                          <td>
                            <Skeleton/>
                          </td>
                          <td>  <Skeleton/></td>
                          <td> <Skeleton/></td>
                          <td> <Skeleton/></td>
                          <td>
                             <Skeleton/>
                          </td>
                        </tr>
                       

                      </tbody>

                    </table>
                  </div>
                </article>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
               

              </div>
            </div>
          </div>

    </SkeletonTheme>

   </div>



   <div class="col-md-7 col-sm-7 mt-10 boxmain" v-else>
    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                   {{$t('business.title')}}
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12col-md-12 block-1">
                <article class="art_text table-responsive-test">

                  <form method="post" @submit="mySearch" id="my_search" class='contact-form'>
                      <div class="row">
                      
                        <div class="col-sm-6 form-group ">
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                           
                            autocomplete="off"
                            
                            :placeholder="$t('general.business')" 
                          
                          />

                          <button class="btn btn-sm btn-warning mt-3" type="submit">
                            <i class="fa fa-search-plus"></i> {{$t('general.search_business')}}
                          </button>
                        </div>
                        <div class="col-sm-6 form-group  mb-3">


                              <select v-if="location!=null" name="location" :placeholder="$t('vehicle.location')"   class="form-control" >
                                       <option  value="">   {{$t('vehicle.location')}}  </option>
                                       <option v-for="(v,k) in location" :key="k" :value="v.id">{{v.name}}</option>
                                    </select>

                        
                        </div>
                         
                      </div>
                    </form>


                  <div class="table-responsive">
                    <table class="table table-bordered" id="tableInvoice">
                      <thead>
                        <tr>
                          <th>
                             {{$t('business.name')}}
                          </th>
                          <th>
                               {{$t('business.address')}}

                          </th>
                           <th style="width: 130px;">
                            
                            Email
                          </th>
                          <th>
                            
                             {{$t('business.phone')}}
                          </th>
                        
                          <th> {{$t('business.ticker')}}</th>
                          <th>Website</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-for="(b,k) in paginationslice(my_data, 20, page)" :key="k">
                          <td><strong>
                              {{b.name}}
                          </strong></td>
                         
                          <td>{{b.address=="undefined" || b.address=='null' ? "" : b.address}} </td>
                          <td>
                            <small>
                               {{b.email=="undefined"? "" : b.email}}
                              </small>
                          </td>
                          <td>
                            {{b.phone=="undefined" || b.phone=='null'? "" : b.phone}}
                         
                          {{b.fax=="undefined"? "" : b.fax}}
                        </td>
                          <td>
                            {{b.ticketcounter}}
                          </td>
                          <td>
                            {{b.website=="undefined"? "" : b.website}}
                          </td>
                        </tr>
                       

                      </tbody>

                    </table>
                  </div>
                  <nav class="pagination-nav pdt-30" v-if="my_data.length>20">
                  

                    <VuePagination
                    :page-count="Math.floor(my_data.length / 20)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination-list text-center'"
                    :page-class="'page-numbers'"
                  >
                  </VuePagination>
                </nav>
                </article>
              </div>
            </div>
           
          </div>

    </SkeletonTheme>

   </div>



</template>


<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
  import axios from 'axios';
export default {
  
  "name":"Business",
  data() {
    return {
      

      myurl:window.my_url,
      my_data:null,
      main:null,
      mycate:null,


      page: 1,
      is_loading:true,
      location:null
      
    };
  },

  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination
    
  },
  created(){
           let p1= axios.get(window.my_api+"api/business/get-businesss",
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
              .then( (res)=>{
                  
                  if(res.status==200){

                       this.main=this.my_data=res.data.data.sort( (a,b)=> {
                            if(a.name  > b.name ){
                                return 1;
                            }
                            if(a.name  < b.name ){
                                return -1;
                            }
                            return 0;

                       });

                                            
                  }else {
                    console.log("ERROR",res);
                  }
                   

                });
            
          let p2=   axios.get(window.my_api + "api/location/get-locations", {
                  headers: window.my_header
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.location  = res.data.data;
                 
                  } else {
                    alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
                  }
          });


          Promise.all([p1,p2]).then(()=>{
              this.is_loading=false;
          });

      
  },
  methods:{
   
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    changePage(e) {
      this.page = e;
    },
    mySearch:function(event){

           event.preventDefault();
        
         let myForm = document.getElementById("my_search");

           let form = new FormData(myForm);


          this.my_data=this.main.filter( (n)=>{
                if(form.get("name").length && form.get("location").length){
                  return    n.name.toUpperCase().search(form.get("name").toUpperCase() ) > -1 && n.codeLocation.toUpperCase().search(form.get("location") ) > -1;
                }

                if(form.get("name").length){
                  return    n.name.toUpperCase().search(form.get("name").toUpperCase() ) > -1;
                }
                if(form.get("location").length && n.codeLocation){
                  
                  return    n.codeLocation.search(form.get("location") ) > -1;
                }

                 if(form.get("name").length==0 && form.get("location").length==0){
                      return   true;
                }
                return false;


          });

        return false;
    }
  }
}
</script>
<style type="text/css">
   #tableInvoice td ,#tableInvoice tr td, #tableInvoice tr th{
      font-size: 11.5px;
    }
     #tableInvoice tr th small{
       font-size: 11px;
     }
</style>
